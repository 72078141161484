import {
	AreaAtuacaoMatch,
	AreaConhecimentoMatch,
	AreaInteresseMatch,
	CapacitacaoMatch,
	CertificacaoMatch,
	CompetenciaMatch,
	ExperienciaMatch,
	FormacaoMatch,
	IdiomaMatch,
	ParametrosMatch,
	SeloMatch,
	VinculoMatch
} from '@core/models/parametros-match';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Message, MessageService } from 'primeng/api';

import { FeedbackModal } from '@app/core/models/feedback-modal';
import { MatchService } from '@core/services/match.service';
import { PesquisarCurriculoApiService } from './../services/pesquisar-curriculo-api.service';
import { RetornoCurriculo } from '@pesquisarCurriculo/models/retorno-curriculo';
import { Subscription } from 'rxjs';
import { UiService } from '@core/services/ui.service';

@Component({
	templateUrl: './pesquisar-curriculo.component.html',
	providers: [MessageService],
	styleUrls: ['../item-resultado-curriculo/item-resultado-curriculo.component.scss']
})
export class PesquisarCurriculoComponent implements OnInit, OnDestroy {
	parametrosFiltro = new ParametrosMatch();
	listaCurriculos: any[] = [];
	possuiRequisito = false;
	retorno?: RetornoCurriculo;
	firstPage = 1;
	itemsPorPagina = 10;
	descricaoFiltro?: string;
	exibirDica = false;
	exibirDicaTopicos = false;
	topmessage: Message[] = [];

	subscriptions: Subscription[] = [];

	niveisProporcao = [
		{nome: 'Alta', valor: 75},
		{nome: 'Média', valor: 50}, 
		{nome: 'Baixa', valor: 15},
		{nome: 'Todos os tópicos', valor: 0}
	];
	proporcao = 15;

	constructor(
		private matchService: MatchService,
		private messageService: MessageService,
		private pesquisarCurriculoApi: PesquisarCurriculoApiService,
		private ui: UiService,
		private cdr: ChangeDetectorRef
	) {}

	ngOnInit() {
		this.verificarExistenciaFiltro();
	}

	ngOnDestroy() {
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
	}

	atualizarFiltro(event: ParametrosMatch) {
		this.parametrosFiltro = event;
		this.retorno = undefined;
		this.verificarExistenciaFiltro();
	}

	removerUF() {
		this.parametrosFiltro.ufOrgao = undefined;
		this.retorno = undefined;
		this.verificarExistenciaFiltro();
	}

	removerUorg() {
		this.parametrosFiltro.nomeUorg = undefined;
		this.retorno = undefined;
		this.verificarExistenciaFiltro();
	}

	removerSelo(selo: SeloMatch) {
		const index = this.parametrosFiltro.selos.indexOf(selo);
		this.parametrosFiltro.selos.splice(index, 1);
		this.retorno = undefined;
		this.verificarExistenciaFiltro();
	}

	removerOrgao() {
		this.parametrosFiltro.orgao = undefined;
		this.parametrosFiltro.codigoOrgao = 0;
		this.retorno = undefined;
		this.verificarExistenciaFiltro();
	}

	removerNome() {
		this.parametrosFiltro.nome = undefined;
		this.retorno = undefined;
		this.verificarExistenciaFiltro();
	}

	removerCargo() {
		this.parametrosFiltro.cargo = undefined;
		this.retorno = undefined;
		this.verificarExistenciaFiltro();
	}

	removerCidade() {
		this.parametrosFiltro.nomeMunicipio = undefined;
		this.retorno = undefined;
		this.verificarExistenciaFiltro();
	}

	removerVinculo(vinculo: VinculoMatch) {
		const index = this.parametrosFiltro.vinculos.indexOf(vinculo);
		this.parametrosFiltro.vinculos.splice(index, 1);
		this.retorno = undefined;
		this.verificarExistenciaFiltro();
	}

	removerCompetencia(competencia: CompetenciaMatch) {
		const index = this.parametrosFiltro.competencias.indexOf(competencia);
		this.parametrosFiltro.competencias.splice(index, 1);
		this.retorno = undefined;
		this.verificarExistenciaFiltro();
	}

	removerIdioma(idioma: IdiomaMatch) {
		const index = this.parametrosFiltro.idiomas.indexOf(idioma);
		this.parametrosFiltro.idiomas.splice(index, 1);
		this.retorno = undefined;
		this.verificarExistenciaFiltro();
	}

	removerExperiencia(experiencia: ExperienciaMatch) {
		const index = this.parametrosFiltro.experiencias.indexOf(experiencia);
		this.parametrosFiltro.experiencias.splice(index, 1);
		this.retorno = undefined;
		this.verificarExistenciaFiltro();
	}

	removerCapacitacao(capacitacao: CapacitacaoMatch) {
		const index = this.parametrosFiltro.capacitacoes.indexOf(capacitacao);
		this.parametrosFiltro.capacitacoes.splice(index, 1);
		this.retorno = undefined;
		this.verificarExistenciaFiltro();
	}

	removerCertificacao(certificacao: CertificacaoMatch) {
		const index = this.parametrosFiltro.certificacoes.indexOf(certificacao);
		this.parametrosFiltro.certificacoes.splice(index, 1);
		this.retorno = undefined;
		this.verificarExistenciaFiltro();
	}

	removerAreaAtuacao(areaAtuacao: AreaAtuacaoMatch) {
		const index = this.parametrosFiltro.areasAtuacao.indexOf(areaAtuacao);
		this.parametrosFiltro.areasAtuacao.splice(index, 1);
		this.retorno = undefined;
		this.verificarExistenciaFiltro();
	}

	removerAreaInteresse(area: AreaInteresseMatch) {
		const index = this.parametrosFiltro.areasInteresse.indexOf(area);
		this.parametrosFiltro.areasInteresse.splice(index, 1);
		this.retorno = undefined;
		this.verificarExistenciaFiltro();
	}

	removerFormacao(formacao: FormacaoMatch) {
		const index = this.parametrosFiltro.formacoes.indexOf(formacao);
		this.parametrosFiltro.formacoes.splice(index, 1);
		this.retorno = undefined;
		this.verificarExistenciaFiltro();
	}

	removerConhecimento(conhecimento: AreaConhecimentoMatch) {
		const index = this.parametrosFiltro.areasConhecimento.indexOf(conhecimento);
		this.parametrosFiltro.areasConhecimento.splice(index, 1);
		this.retorno = undefined;
		this.verificarExistenciaFiltro();
	}

	irParaCesta() {}

	/**
	 * Evento de paginacao
	 * @param event
	 */
	paginar(event: any) {
		this.firstPage = event.first;
		this.pesquisar();
	}

	/**
	 * Seta faz a pesquisa trazendo a primeira página de resultados
	 */
	pesquisaInicial() {
		this.firstPage = 1;
		this.pesquisar();
	}

	limpar() {
		this.parametrosFiltro = new ParametrosMatch();
		this.retorno = undefined;
		this.verificarExistenciaFiltro();
	}

	retornoItemCurriculo(feedback: FeedbackModal) {
		if (feedback.mensagemFeedback) {
			this.messageService.add(feedback.mensagemFeedback![0]);
		}

		if (feedback.reload) {
			this.pesquisaInicial();
		}
	}

	private pesquisar() {
		this.retorno = undefined;
		const pagina = Math.trunc(this.firstPage / this.itemsPorPagina) + 1;
		const parametros = this.matchService.prepararParametrosParaPesquisaCurriculo(this.parametrosFiltro);

		this.pesquisarElasticSearch(parametros, pagina);
	}

	private pesquisarElasticSearch(parametros: ParametrosMatch, pagina: number) {
		this.subscriptions.push(
			this.pesquisarCurriculoApi.matchPesquisarCurriculosElasticSearch(parametros, pagina).subscribe(
				async res => {
					if (await res) {
						this.retorno = res;
						this.montarDescricaoFiltro();
						this.cdr.detectChanges();
					}
				},
				err => {
					this.topmessage.push(this.ui.criarMensagem('error', '', err.error.message));
				}
			)
		);
	}

	private montarDescricaoFiltro() {
		this.descricaoFiltro = '';
		this.descricaoVinculo();
		this.descricaoSoftskill();
		this.descricaoIdioma();
		this.descricaoExperiencia();
		this.descricaoCapacitacao();
		this.descricaoCertificacao();
		this.descricaoAreaAtuacao();
		this.descricaoAreaInteresse();
		this.descricaoFormacao();
		this.descricaoConhecimentos();
		this.descricaoOrgao();
		this.descricaoNomes();
		this.descricaoCidades();
		this.descricaoCargos();
		this.descricaoUfs();
		this.descricaoUorgs();
		this.descricaoSelo();
	}

	private descricaoVinculo() {
		if (this.parametrosFiltro.vinculos.length > 0) {
			let texto = '<li>Vínculo do tipo ';
			for (const vinculo of this.parametrosFiltro.vinculos) {
				texto = texto + '"' + vinculo.nome?.toLowerCase() + '"';
				if (this.parametrosFiltro.vinculos[this.parametrosFiltro.vinculos.length - 1].id != vinculo.id) {
					texto = texto + ' e ';
				}
			}
			this.descricaoFiltro = this.descricaoFiltro + texto + '</li> ';
		}
	}

	private descricaoSoftskill() {
		if (this.parametrosFiltro.competencias.length > 0) {
			let texto = '<li>Softskill do tipo ';
			for (const competencia of this.parametrosFiltro.competencias) {
				texto = texto + '"' + competencia.nome?.toLowerCase() + '"';
				if (
					this.parametrosFiltro.competencias[this.parametrosFiltro.competencias.length - 1].idCompetencia !=
					competencia.idCompetencia
				) {
					texto = texto + ' e ';
				}
			}
			this.descricaoFiltro = this.descricaoFiltro + texto + '</li> ';
		}
	}

	private descricaoIdioma() {
		if (this.parametrosFiltro.idiomas.length > 0) {
			let texto = '<li>Idioma do tipo ';
			for (const idioma of this.parametrosFiltro.idiomas) {
				texto = texto + '"' + idioma.nome?.toLowerCase() + '"';
				if (this.parametrosFiltro.idiomas[this.parametrosFiltro.idiomas.length - 1].idIdioma != idioma.idIdioma) {
					texto = texto + ' e ';
				}
			}
			this.descricaoFiltro = this.descricaoFiltro + texto + '</li> ';
		}
	}

	private descricaoExperiencia() {
		if (this.parametrosFiltro.experiencias.length > 0) {
			let texto = '<li>Experiência profissional com nome similar a ';
			for (const experiencia of this.parametrosFiltro.experiencias) {
				texto = texto + '"' + experiencia.nomeExperiencia?.toLowerCase() + '"';
				if (
					this.parametrosFiltro.experiencias[this.parametrosFiltro.experiencias.length - 1].nomeExperiencia !=
					experiencia.nomeExperiencia
				) {
					texto = texto + ' e ';
				}
			}
			this.descricaoFiltro = this.descricaoFiltro + texto + '</li> ';
		}
	}
	private descricaoCapacitacao() {
		if (this.parametrosFiltro.capacitacoes.length > 0) {
			let texto = '<li>Capacitação com nome similar a ';
			for (const capacitacao of this.parametrosFiltro.capacitacoes) {
				texto = texto + '"' + capacitacao.nomeCapacitacao?.toLowerCase() + '"';
				if (
					this.parametrosFiltro.capacitacoes[this.parametrosFiltro.capacitacoes.length - 1].nomeCapacitacao !=
					capacitacao.nomeCapacitacao
				) {
					texto = texto + ' e ';
				}
			}
			this.descricaoFiltro = this.descricaoFiltro + texto + '</li> ';
		}
	}
	private descricaoCertificacao() {
		if (this.parametrosFiltro.certificacoes.length > 0) {
			let texto = '<li>Certificação com nome similar a ';
			for (const certificacao of this.parametrosFiltro.certificacoes) {
				texto = texto + '"' + certificacao.nomeCertificacao?.toLowerCase() + '"';
				if (
					this.parametrosFiltro.certificacoes[this.parametrosFiltro.certificacoes.length - 1].nomeCertificacao !=
					certificacao.nomeCertificacao
				) {
					texto = texto + ' e ';
				}
			}
			this.descricaoFiltro = this.descricaoFiltro + texto + '</li> ';
		}
	}
	private descricaoAreaAtuacao() {
		if (this.parametrosFiltro.areasAtuacao.length > 0) {
			let texto = '<li>Área de atuação do tipo ';
			for (const areaAtuacao of this.parametrosFiltro.areasAtuacao) {
				texto = texto + '"' + areaAtuacao.nome?.toLowerCase() + '"';
				if (
					this.parametrosFiltro.areasAtuacao[this.parametrosFiltro.areasAtuacao.length - 1].idAreaAtuacao !=
					areaAtuacao.idAreaAtuacao
				) {
					texto = texto + ' e ';
				}
			}
			this.descricaoFiltro = this.descricaoFiltro + texto + '</li> ';
		}
	}
	private descricaoAreaInteresse() {
		if (this.parametrosFiltro.areasInteresse.length > 0) {
			let texto = '<li>Área de interesse do tipo ';
			for (const areaInteresse of this.parametrosFiltro.areasInteresse) {
				texto = texto + '"' + areaInteresse.nome?.toLowerCase() + '"';
				if (
					this.parametrosFiltro.areasInteresse[this.parametrosFiltro.areasInteresse.length - 1].idAreaInteresse !=
					areaInteresse.idAreaInteresse
				) {
					texto = texto + ' e ';
				}
			}
			this.descricaoFiltro = this.descricaoFiltro + texto + '</li> ';
		}
	}

	private descricaoFormacao() {
		if (this.parametrosFiltro.formacoes.length > 0) {
			let texto = '<li>Formação acadêmica do tipo ';
			for (const formacao of this.parametrosFiltro.formacoes) {
				texto = texto + '"' + formacao.nomeNivelCurso?.toLowerCase();
				if (formacao.nomeFormacao) {
					texto = texto + ' em ' + formacao.nomeFormacao.toLowerCase();
				}
				texto = texto + '" e ';
			}
			texto = texto.slice(0, -4);
			this.descricaoFiltro = this.descricaoFiltro + texto + '"' + '</li> ';
		}
	}

	private descricaoSelo() {
		if (this.parametrosFiltro.selos.length > 0) {
			let texto = '<li>Medalhas Digitais ';
			for (const selo of this.parametrosFiltro.selos) {
				texto = texto + '"' + selo.nomeSelo?.toUpperCase();
				if (selo.nomeInstituicao) {
					texto = texto + ' em ' + selo.nomeInstituicao.toUpperCase();
				}
				texto = texto + '" e ';
			}
			texto = texto.slice(0, -4);
			this.descricaoFiltro = this.descricaoFiltro + texto + '"' + '</li> ';
		}
	}
	private descricaoConhecimentos() {
		if (this.parametrosFiltro.areasConhecimento.length > 0) {
			let texto = '<li>Conhecimento técnico do tipo ';
			for (const conhecimento of this.parametrosFiltro.areasConhecimento) {
				texto = texto + '"' + conhecimento.nome?.toLowerCase() + '"';
				if (
					this.parametrosFiltro.areasConhecimento[this.parametrosFiltro.areasConhecimento.length - 1].idAreaConhecimento !=
					conhecimento.idAreaConhecimento
				) {
					texto = texto + ' e ';
				}
			}
			this.descricaoFiltro = this.descricaoFiltro + texto + '</li> ';
		}
	}
	private descricaoUfs() {
		if (this.parametrosFiltro.ufOrgao) {
			const texto = '<li>UF ' + '"' + this.parametrosFiltro.ufOrgao.toUpperCase() + '"';
			this.descricaoFiltro = this.descricaoFiltro + texto + '</li> ';
		}
	}
	private descricaoUorgs() {
		if (this.parametrosFiltro.nomeUorg) {
			const texto = '<li>Uorg ' + '"' + this.parametrosFiltro.nomeUorg?.toUpperCase() + '"';
			this.descricaoFiltro = this.descricaoFiltro + texto + '</li> ';
		}
	}

	private descricaoOrgao() {
		if (this.parametrosFiltro.orgao) {
			const texto = '<li>Orgao  ' + '"' + this.parametrosFiltro.orgao.nome?.toUpperCase() + '"';
			this.descricaoFiltro = this.descricaoFiltro + texto + '</li> ';
		}
	}
	private descricaoNomes() {
		if (this.parametrosFiltro.nome) {
			const texto = '<li>Nomes com nome similar a "' + this.parametrosFiltro.nome?.toUpperCase() + '"';
			this.descricaoFiltro = this.descricaoFiltro + texto + '</li> ';
		}
	}
	private descricaoCidades() {
		if (this.parametrosFiltro.nomeMunicipio) {
			const texto = '<li>Municipio ' + '"' + this.parametrosFiltro.nomeMunicipio.toUpperCase() + '"';
			this.descricaoFiltro = this.descricaoFiltro + texto + '</li> ';
		}
	}
	private descricaoCargos() {
		if (this.parametrosFiltro.cargo) {
			const texto = '<li>Cargo com nome similar a "' + this.parametrosFiltro.cargo?.toUpperCase() + '"';
			this.descricaoFiltro = this.descricaoFiltro + texto + '</li> ';
		}
	}

	private verificarExistenciaFiltro() {
		this.possuiRequisito = this.matchService.verificarSePossuiRequisitosMatch(this.parametrosFiltro);
	}
}
