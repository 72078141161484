<p-messages [enableService]="true" [closable]="false"></p-messages>

<form>
	<div class="row">
		<div class="col-12">
			<h3 data-cy="titulo-pagina">Pesquisar edital</h3>

			<!--Barra Pesquisa-->
			<div class="row">
				<div class="col-sm-112 p-inputgroup br-input">
					<input
						#elTextoPesquisa
						name="textoPesquisa"
						pInputText
						type="text"
						data-cy="campo-texto"
						[(ngModel)]="textoPesquisa"
						maxlength="100"
						size="90%"
						placeholder="Busque aqui pelo número do edital ou nome da oportunidade"
					/>
					<button
						id="btnPesquisar"
						data-cy="botao-pesquisar"
						class="primary mt-3 mt-sm-0 ml-sm-3"
						(click)="pesquisaInicial()"
						type="submit"
						pButton
					>
						Pesquisar
					</button>
					<button
						id="btnLimpar"
						data-cy="botao-limpar"
						class="secondary mt-3 mt-sm-0 ml-sm-3"
						(click)="limpar()"
						type="button"
						pButton
					>
						Limpar
					</button>
				</div>
			</div>

			<!--Filtros-->
			<div class="mt-3">
				<a
					id="btnPesquisaAvancada"
					data-cy="botao-pesquisa-avancada"
					(click)="changeExibirFiltro()"
					class="br-button secondary small"
					>{{labelPesquisaAvancada}}</a
				>
			</div>

			<div class="my-3 p-2 shadow-sm bg-gray-2" *ngIf="exibirFiltro" data-cy="pesquisa-avancada">
				<div class="row">
					<div class="col-sm-4 br-input">
						<label for="idSituacao" class="d-block">Situação</label>
						<p-dropdown
							name="selectSituacaoEdital"
							[options]="situacoesEdital"
							[(ngModel)]="selectSituacaoEdital"
							optionLabel="name"
							placeholder="Selecione"
							[showClear]="true"
							inputId="situacao"
							id="situacao"
							data-cy="campo-situacao"
						></p-dropdown>
					</div>
					<div class="col-sm-7 br-input">
						<sgp-busca-orgao
							id="idOrgaos"
							inputId="idOrgaos"
							label="Órgão"
							name="selectOrgao"
							[ngModel]="selectOrgao"
							ngDefaultControl
							(ngModelUpdated)="atualizarOrgao($event)"
							data-cy="campo-orgao"
						></sgp-busca-orgao>
					</div>
				</div>
				<div class="row mt-2">
					<div class="col-sm-4 br-input has-icon">
						<label for="idPeriodoPublicacao">Período de publicação</label>
						<p-calendar
							[showIcon]="true"
							icon="fas fa-calendar-alt"
							name="dtPeriodoPublicacao"
							[(ngModel)]="dtPeriodoPublicacao"
							selectionMode="range"
							[readonlyInput]="false"
							inputId="periodoPublicacao"
							id="periodoPublicacao"
							dateFormat="dd/mm/yy"
							rangeSeparator=" até "
							placeholder="dd/mm/aaaa até dd/mm/aaaa"
							data-cy="campo-periodo"
							(onClickOutside)="verificarDuasDatasPreenchidas()"
						></p-calendar>
					</div>
					<div class="col-sm-7">
						<sgp-busca-localidade
							id="localidade"
							label="Localidade"
							name="localidade"
							[ngModel]="localidade"
							ngDefaultControl
							(ngModelUpdated)="atualizarLocalidade($event)"
							data-cy="campo-localidade"
						>
						</sgp-busca-localidade>
					</div>
				</div>
			</div>
		</div>
	</div>
</form>

<div *ngIf="loading" id="loading">
	<sgp-skeleton-pesquisar-edital></sgp-skeleton-pesquisar-edital>
</div>

<!--Resultado-->

<div id="resultado" class="row mt-4" *ngIf="retorno">
	<div id="quantidadeResultados" class="col-12 mb-3" data-cy="quantidade-resultados">
		<p *ngIf="retorno!.metadata.total === 0">Nenhum resultado encontrado</p>
		<p *ngIf="retorno!.metadata.total === 1">Foi encontrado 1 edital</p>
		<p *ngIf="retorno!.metadata.total > 1">Foram encontrados {{ retorno.metadata.total | numero }} editais</p>
	</div>

	<p-dataView
		[value]="retorno.items"
		*ngIf="retorno.metadata.total > 0"
		[paginator]="true"
		[rows]="sizePageEditais"
		[totalRecords]="retorno.metadata.total"
		[lazy]="true"
		(onPage)="paginarEditais($event)"
		[first]="firstPageEditais"
		data-cy="resultados-pesquisa"
	>
		<ng-template pTemplate="list" let-items>
			<div class="col-sm-12 col-md-12 py-4" *ngFor="let edital of items">
				<div class="row">
					<div class="col-sm-10 col-md-10 pt-1">
						<a
							[routerLink]="['/edital', edital.id]"
							routerLinkActive="router-link-active"
							queryParamsHandling="merge"
							class="text-weight-semi-bold text-up-01 text-primary-default text-decoration-none"
							><span data-cy="numero-edital">
								{{ edital.id}} - Edital {{edital.numeroEdital | numeroEdital}} - {{edital.nomeOrgao}}</span
							></a
						>
					</div>
					<div class="col-sm-2 col-md-2 text-right">
						<sgp-botao-acao
							[edital]="edital"
							pagina="pesquisar_edital"
							(mensagemFeedback)="atualizarMensagem($event)"
							(editalAtualizado)="atualizarEdital($event)"
							*ngIf="edital"
							data-cy="botao-acao-edital"
						></sgp-botao-acao>
					</div>
				</div>
				<div class="row">
					<div class="col">
						<div data-cy="situacao-edital"><label>Situação:</label> {{edital.situacao?.descricao}}</div>
						<div *ngIf="edital.dataPublicacao"><label>Data de publicação:</label> {{edital.dataPublicacao}}</div>
						<div data-cy="oportunidades-edital">
							<label>Oportunidade(s): </label>
							<span *ngIf="edital.oportunidades.length === 0"> Nenhuma oportunidade cadastrada ainda</span>
						</div>
						<ul>
							<li *ngFor="let op of edital.oportunidades.slice(0, 2)" data-cy="nome-oportunidade">
								{{op.nomeOportunidade}}
								<span *ngIf="op.unidade">na Unidade {{op.unidade}}</span>
								<span *ngIf="op.localidade">{{op.localidade}}</span>
							</li>

							<li *ngIf="edital.oportunidades.length > 3">E mais...</li>
						</ul>
					</div>
				</div>
			</div>
		</ng-template>
	</p-dataView>
</div>
